import React from 'react';
import PropTypes from 'prop-types';
// import fetch from 'isomorphic-unfetch';
// import { searchItunes } from '../lib/searchItunes';
import Router from 'next/router';

import { connect } from 'react-redux';
import { NextSeo } from 'next-seo';

// import { Col, Row } from 'antd';
import { spacing, maxScreenSize } from '../lib/theme';
import { retrieveLocally, staticAssetsPrefix } from '../lib/utils';
import AuthComponent from '../components/Auth/AuthComponent';
import LoadingSpinner from '../components/Common/Loading';


class Login extends React.Component {
  static async getInitialProps(context) {
    const type = context.query.type;
    return { type };
    // console.dir(queryText);
    // if (typeof queryText !== 'undefined' && queryText.length > 0) {
    //   // const response = await fetch(searchItunes(queryText));
    //   // const json = await response.json();
    //   // return { podcasts: json.results, queryText };
    //   return { queryText };
    // } else {
    //   return { queryText };
    // }
  }

  constructor(props) {
    super(props);
    this.state = { offlineMessage: false, showInterests: false, userData: {} };
    // this.props.loadingUserDetailsState(true);
  }

  componentDidMount() {
    // const { queryText } = this.props;
    // const podcasts = await this.setPageData(queryText);
    // this.setState({ podcasts, searching: false });

    const urlParams = new URLSearchParams(window.location.search);
    const showInterestsQuery = urlParams.get('showInterests');
    const showInterests = showInterestsQuery === 'true';
    const userData = retrieveLocally('authUserData', true) || '{}'; // urlParams.get('userData') || '{}';

    this.setState({ showInterests, userData: JSON.parse(userData) });

    this.offlineTimeout = setTimeout(function () {
      this.setState({ offlineMessage: true });
    }.bind(this), 5000);
  }

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const showInterestsQuery = urlParams.get('showInterests');
    const showInterests = showInterestsQuery === 'true';
    const { userDetails } = this.props;
    const { loadingUserDetails } = this.props.loadingState;
    // if (queryText !== prevProps.queryText) {
    //   this.setState({ podcasts: undefined, searching: true });
    //   const podcasts = await this.setPageData(queryText);
    //   this.setState({ podcasts, searching: false });
    // }
    if (prevProps.loadingState && loadingUserDetails !== prevProps.loadingState.loadingUserDetails) {
      if (!loadingUserDetails && userDetails && userDetails.uid && !showInterests) {
        // go to home
        Router.push({ pathname: '/home' });
      }
    }
  }

  componentWillUnmount() {
    if (this.offlineTimeout) {
      clearTimeout(this.offlineTimeout);
    }
  }

  // async setPageData(queryText) {
  //   // const response = await fetch(searchItunes(queryText), {
  //   //   headers: {
  //   //     "Content-Type": "application/json",
  //   //     "Accept": "application/json"
  //   //   }
  //   // });
  //   // const json = await response.json();
  //   // return json.results;
  // }

  onCallbackAuthChild = (data) => {
    if (data && data.visible === false) {
      setTimeout(() => {
        Router.push({ pathname: '/home' });
      }, 1200);
    }
  }

  render() {
    // console.dir(this.props);
    const { userDetails, type } = this.props;
    const { loadingUserDetails } = this.props.loadingState;
    const { offlineMessage, showInterests, userData } = this.state;
    const typeId = type === 'signUp' ? 'signUp' : 'signIn';
    // console.dir(podcasts);
    return (
      <div className='main-page'>
        <NextSeo
          title='Podurama login page'
          description='Podurama login page'
          canonical='https://podurama.com/login'
        // openGraph={{
        //   type: 'website',
        //   locale: 'en_IE',
        //   url: `https://podurama.com/login`,
        //   site_name: 'Podurama',
        // }}
        // twitter:{
        //     handle: '@handle',
        //     site: '@site',
        //     cardType: 'summary_large_image',
        // }
        />

        <div className='local-bg'>
          {loadingUserDetails && <>
            <LoadingSpinner />
            <br />
            {offlineMessage && <p style={{ color: 'white' }}>You might be offline. Please check your connection.</p>}
          </>}
          {((!loadingUserDetails && !userDetails) || showInterests) &&
            <AuthComponent parent='loginPage' typeId={typeId} callbackAuthParent={this.onCallbackAuthChild} showInterests={showInterests} userData={userData} />
          }
        </div>
        <style jsx>
          {`
            .main-page {
              text-align: center;
              background: white; hsla(235.9, 33.7%,22.2%, 1); //lightgray;
              position: absolute;
              width: 100%;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 100;
              // background: white;
              // padding: ${spacing('xxl')};
            }
            .local-bg {
              padding: 10% 20%;
              min-height: 100vh;
              // background: hsla(235.9, 33.7%,22.2%, 1); //lightgray;
              // clip-path: ellipse(94% 87% at 65% 15%);
              background: linear-gradient(rgba(0,0,0,0.85), rgba(0,0,0,0.85)), url(${staticAssetsPrefix}/static/images/bg.jpg);
              // backdrop-filter: brightness(50%);
            }
            @media ${maxScreenSize.tablet} {
              .local-bg {
                padding: 15% 5%;
              }
            }
        `}
        </style>
      </div>
    )
  }
}

Login.propTypes = {
  queryText: PropTypes.string,
  loadingState: PropTypes.object,
  userDetails: PropTypes.object,
  type: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userDetails,
    loadingState: state.loadingState,
  }
}

export default connect(mapStateToProps, null)(Login);
